import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductService from '../services/productService';
import CalzadoService from '../services/calzadoService';
import CategoryService from '../services/categoryService';
import CarritoService from '../services/carritoService';

const Detalle = ({ talles, añadidos, setAñadidos }) => {
    const params = useParams();
    const [calzadoToShow, setCalzadoToShow] = useState(null);
    const [productToShow, setProductToShow] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const [tallesCategoria, setTallesCategoria] = useState([]);
    const [talleIngresado, setTalleIngresado] = useState("");
    const [colorIngresado, setColorIngresado] = useState("Negro");
    const [coloresDisponibles, setColoresDisponibles] = useState([]);
    const [tallesDisponibles, setTallesDisponibles] = useState([]);
    const [defaultTalleValue, setDefaultTalleValue] = useState("")
    const [cantProducto, setCantProducto] = useState(0);
    const [enableTalle, setEnableTalle] = useState(true);

    /*useEffect(() => {
        //Para animaciones de webflow                                    
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '63bdb366c764e8497b0a04d2');
        html.setAttribute('data-wf-site', '63b592a8f6d390693735c1ad');
                                                         
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);*/

    let navigate = useNavigate();

    const añadirALCarro = () => {

        if (!talleIngresado == "") {
            if (cantProducto > 0) {
                //buscar producto a añadir segun talle y color en productToShow
                const found = productToShow.find(element =>
                    element.color == colorIngresado &&
                    element.talle == talleIngresado
                );
                showToast('Producto añadido al carro', 'success');

                const newProduct = {
                    id: found.id,
                    calzadoId: calzadoToShow.id,
                    precio: calzadoToShow.precio,
                    color: colorIngresado,
                    talle: talleIngresado,
                    cantidad: Number(cantProducto),
                    nombre: calzadoToShow.nombre
                }
                console.log("Nuevo ** Producto", newProduct);
                CarritoService.addProduct(newProduct);          // añade nuevo producto al carro
                setAñadidos(añadidos + newProduct.cantidad);    // incrementa el contador del carro
                navigate("/productos");
            } else {
                showToast('Debe ingresar una cantidad', 'warning');
            }
        } else {
            showToast('Debe ingresar un talle', 'warning');
        }
    };

    const getCantidad = (e) => {
        var productoArmado = productToShow.find((p) => colorIngresado === p.color && talleIngresado === p.talle);

        if (e.target.value > 0 && e.target.value <= productoArmado.stock) {
            setCantProducto(e.target.value);
        }

        else {
            setCantProducto(productoArmado.stock);
            showToast("Cantidad máxima :" + productoArmado.stock.toString(), 'warning');
        }
    };

    function showToast(msjeToast, estilo) {
        if (estilo == 'success') {
            toast.success(msjeToast, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
        if (estilo == 'warning') {
            toast.warning(msjeToast, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    function seteaColor(e) {
        setColorIngresado(e.target.value);
        if (!e.target.value == "") {
            setEnableTalle(true); // habilita select de talles
            setCantProducto(0);     // cant productos 0
        } else {
            setEnableTalle(false);  // deshabilita select de talles
        }

        //-- get talles disponibles segun el color seleccionado
        var talles = [];
        //var prodFiltered = res3.data;
        for (let i = 0; i < productToShow.length; i++) {
            if (!talles.includes(productToShow[i].talle)) {
                if (e.target.value == productToShow[i].color) {
                    talles.push(productToShow[i].talle);
                }
            }
        }
        talles = (talles.sort((a, b) => a - b));
        setTalleIngresado("")
        setTallesDisponibles(talles.map(talle =>
        ({
            id: talle,
            name: talle
        })).sort((a, b) => a - b));    // const order = array.sort((a, b) => a - b)
    }

    const findProduct = async (e) => {
        //-- busco nombre calzado
        const res = await CalzadoService.findById(params.id);
        if (!res.data == {}) {
            return
        } else {
            setCalzadoToShow(res.data);

            // traigo categoria para tener nombre y los talles
            const res2 = await CategoryService.findById(res.data.categoriaId);
            setCategoria(res2.data);
            setTallesCategoria(res2.data.talles.map(result =>
            ({
                id: result.talle,
                name: result.talle
            })));

            //-- traigo productos con stock
            const res3 = await ProductService.findAll({
                where: {
                    stock: {
                        gt: 0
                    },
                    calzadoId: res.data.id
                }

            });
            setProductToShow(res3.data);


            //-- get colores disponible
            var colores = [];
            var prodFiltered = res3.data;
            for (let i = 0; i < prodFiltered.length; i++) {
                if (!colores.includes(prodFiltered[i].color)) {
                    colores.push(prodFiltered[i].color);
                }
            }
            setColoresDisponibles(colores);

            //--- talles para negro
            //-- get talles disponibles segun el color seleccionado
            var talles = [];
            //var prodFiltered = res3.data;
            for (let i = 0; i < res3.data.length; i++) {
                if (!talles.includes(res3.data[i].talle)) {
                    if (res3.data[i].color == "Negro") {
                        talles.push(res3.data[i].talle);
                    }
                }
            }
            talles = (talles.sort((a, b) => a - b));
            //setTalleIngresado("")
            setTallesDisponibles(talles.map(talle =>
            ({
                id: talle,
                name: talle
            })).sort((a, b) => a - b));    // const order = array.sort((a, b) => a - b)
        }
    };

    useEffect(() => {
        findProduct();



    }, []);

    function talle(e) {     // onChange talle
        setTalleIngresado(e.target.value);
        setCantProducto(0);
    }

    console.log("talles disponibles", tallesDisponibles);


    return (
        <div className="section-4 wf-section">

            {calzadoToShow && (
                <div className="div-block-4">
                    <h2 className="heading-2">{calzadoToShow.nombre}</h2>
                    <div className="wrapperproductos">
                        {calzadoToShow.destacado &&
                            <p className="destac">PRODUCTO DESTACADO</p>
                        }
                        <div className="titulos">
                            <h3 className="heading-3">{calzadoToShow.descripcion}</h3>
                            <div className="text-block-6">{calzadoToShow.precio.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                            <div className="columns-3 w-row">
                                <div className="column-9 w-col w-col-5">
                                    <div className="w-form">
                                        <form id="email-form" onSubmit={e => e.preventDefault()} name="email-form" data-name="Email Form" method="get">
                                            <div className="div-block-6"><label htmlFor="field-2" className="field-label">Colores disponibles</label>
                                                <select id="color" value={colorIngresado} name="field-2" data-name="Field 2" onChange={seteaColor} className="select-field w-select">
                                                    <option value="">Seleccione un color</option>
                                                    {
                                                        coloresDisponibles.map((color, index) => (
                                                            <option key={index} value={color}>{color}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="div-block-6">
                                                <label htmlFor="field" className="field-label">Talles disponibles</label>
                                                <select value={talleIngresado} id="talle" {...{ enabled: enableTalle ? true : undefined, disabled: enableTalle ? undefined : true }} name="field" data-name="Field" onChange={talle} title="Debe seleccionar el color primero" className="select-field w-select">
                                                    <option >Seleccione un talle</option>
                                                    {
                                                        tallesDisponibles.map((talle) => (
                                                            <option key={talle.id} value={talle.name}>{talle.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="div-block-6">
                                                <label htmlFor="field-3" className="field-label">
                                                    Cantidad
                                                </label>
                                                <input type="number" value={cantProducto} className="select-field w-input" onChange={getCantidad} maxLength={256} name="field-3" data-name="Field 3" id="field-3" required />
                                            </div>
                                            <button className="submit-button w-button" onClick={añadirALCarro}>Añadir al carro</button>
                                            <ToastContainer />
                                        </form>
                                        <div className="w-form-done">
                                            <div>Gracias! Your submission has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-3 w-col w-col-7">
                                    <img src={calzadoToShow.imagen} loading="lazy" alt="" className="image-7" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Detalle;
