import React from 'react';

const Footer = () => {
  return (
      <section id="contacto" className="footer-dark wf-section">
          <div className="container" />
          <div className="columns-2 w-row">
              <div className="column-6 w-col w-col-5 w-col-small-small-stack">
                  <a href="#" className="footer-brand w-inline-block"><img src="/images/logoFooter.png" loading="lazy" alt="" className="image-10" /></a>
                  <div className="footer-block direccion">
                      <div className="title-small">CONTACTO</div>
                      <p className="footer-copyright-center">Calzados Over</p>
                      <p className="footer-copyright-center">Padre Vicente Vitale 32</p>
                      <p className="footer-copyright-center">(2109) Acebal -Santa Fe</p>
                      <p className="footer-copyright-center">(03469) 15691073</p>
                      <a href="mailto:Calzadoescolarg@gmail.com" className="footer-link">Calzadoescolarg@gmail.com</a>
                  </div>
              </div>
              <div className="column-7 w-col w-col-3 w-col-small-small-stack">
                  <div id="w-node-_23b8108c-8b3f-9d8c-56a6-3fed7ae5731a-0035c1ae" className="footer-block medio">
                      <div className="title-small">navegacion</div>
                      <a href="#" className="footer-link">Inicio</a>
                      <a href="#" className="footer-link">Sobre nosotros</a>
                      <a href="#" className="footer-link">Productos</a>
                      <a href="#" className="footer-link">Contacto</a>
                  </div>
              </div>
              <div className="column-8 w-col w-col-4 w-col-small-small-stack">
                  <div id="w-node-_23b8108c-8b3f-9d8c-56a6-3fed7ae5732e-0035c1ae" className="footer-block">
                      <div className="title-small">redes sociales</div>
                      <div className="footer-social-block">
                          <a href="https://instagram.com/calzadoescolarg?igshid=YmMyMTA2M2Y=" target="blank" className="footer-social-link w-inline-block"><img src="/images/instaIcon.png" loading="lazy" alt="" /></a>
                          <a href="https://www.facebook.com/profile.php?id=100089108536399&mibextid=LQQJ4d" target="blank" className="footer-social-link w-inline-block"><img src="/images/faceIcon.png" loading="lazy" alt="" /></a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="footer-divider" />
          <div className="footer-copyright-center">Copyright © 2023 overcolegial.com.ar</div>
      </section>
  )
};

export default Footer;