import React from 'react';
import { useEffect, useState } from 'react';
import carritoService from '../services/carritoService';
import mercadoPagoService from '../services/mercadoPagoService';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import config from '../config';
import clientService from '../services/clientService';


const Carro = (props) => {

    /*const promo = {
        promo: 'promo',
        valor: 20
    }*/

    var añadidos = props.añadidos;

    const [promo, setPromo] = useState({
       promo: "",
       valor: 0 
    })

    const setAñadidos = props.setAñadidos;

    var productos = carritoService.getProducts();
    const [totalCompra, setTotalCompra] = useState(0);
    const [datosOn, setDatosOn ] = useState(false);
    const [iframe, setIframe] = useState({ status: false, url: "" });
    const [modalIsOpen, setIsOpen] = useState(false);
    const [promoIngresada, setPromoIngresada] = useState("");
    const [promoOk, setPromoOk] = useState(false);
    const [promoDescuento, setPromoDescuento] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [datosMail, setDatosMail] = useState({
        nombre: "",
        direccion: "",
        ciudad: "",
        codigo: "",
        provincia: "",
        telefono: "",
        colegio: "",
        email: "",
    });
    const [isEnabledConfirm, setIsEnabledConfirm] = useState(true);    // boton confirmar compra

    const openModal = () => {
        setIsOpen(true);
     };
  
    const closeModal = () => {
        setIsOpen(false);
    };

    /*function showToast(msjeToast) {
        toast.success(msjeToast, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    };*/

    function showToast(msjeToast, estilo) {
        if (estilo == 'success') {
            toast.success(msjeToast, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
        if (estilo == 'warning') {
            toast.warning(msjeToast, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    //setAñadidos((JSON.parse(localStorage.getItem("totalQuantity"))));
    if (añadidos == null) {
        setAñadidos(0);
    }
     console.log("añaaña:", añadidos); 

    /*useEffect(() => {
        //Para animaciones de webflow
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '63bdb89ea55d32b5c5cfb695');
        html.setAttribute('data-wf-site', '63b592a8f6d390693735c1ad');
                                                         
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);*/

    //-----------------busco los nombres 

    function calculoTotal() {         // y descuento promo
        var total = 0;
        for (let index = 0; index < productos?.length; index++) {
            let producto = productos[index];
            total = total + producto.precio * producto.cantidad;
        }
        setSubTotal(total)
        setTotalCompra(total);
    };

    function deleteProducto(e, index) {
        carritoService.removeProduct(e, index);
        var stota = subTotal;
        var tota = totalCompra;
        //setTotalCompra(tota - (e.precio * e.cantidad));
        setSubTotal(stota - (e.precio * e.cantidad));
        console.log("+++++++++++", (stota - (e.precio * e.cantidad)));

        localStorage.setItem("totalQuantity", añadidos - e.cantidad);
        setAñadidos(añadidos - e.cantidad);

        // //--------------------------------------------------------------- agregue
        
        var pDesc = (stota - (e.precio * e.cantidad)) * promo.valor / 100;
        setPromoDescuento(pDesc);
        //var tot = tota;
        console.log("================", pDesc);

        setTotalCompra((stota - (e.precio * e.cantidad)) - pDesc);
   
    }


    const mercaPago = async () => {
        // verifico que esten todos los datos del form
        var datos = Object.values(datosMail);
        for(let i=0; i< datos.length; i++){
            if(datos[i] == "") {
                showToast('Faltan datos en el formulario', 'warning');
                return
            }
        }

                
        // Get our input reference.                           // verifico formato de mail
        var emailField = document.getElementById('email');
        // Define our regular expression.
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

        // Using test we can check if the text match the pattern
        if (validEmail.test(emailField.value)) {
            //alert('Email is valid, continue with form submission');
            //return true;
        } else {
            showToast('Formato de Email inválido', 'warning');
            return;
        }

        //-------------------------------------------- Creo cliente
        //verifico si existe
        const esta = await clientService.findAll({where: {email: datosMail.email}} )
        if (esta.data.length == 0) {
            console.log("pasoooo");
            await clientService.create({...datosMail, ventaRealizada: false});          
        } else {
            await clientService.updateField(esta.data[0].id, datosMail);
        }


        setIsEnabledConfirm(false);

        console.log("mercado pago", datosMail);
        const cliente = datosMail;


        const productosConDescuento = productos.map(p => {  // aplico el descuento a los productos

            if (promoOk) {
                console.log("paso por descuentos", p.precio - (p.precio * promo.valor / 100));
                const prod = {
                    ...p,
                    precio: p.precio - (p.precio * promo.valor / 100)
                }
                return prod
            } else {
                return p
            }
        })

        console.log("Lo que manda a mercado--", cliente);

        const venta = { cliente, productos: productosConDescuento };
        console.log("VENTA", venta);
        const res = await mercadoPagoService.create({ data: venta });
        console.log( res.data.respuesta);

        setIsEnabledConfirm(true);

        setIframe({ status: true, url: res.data.respuesta });
        openModal();
    }

    const backToTheMainPage = () => {                           // <-------------------Ver esto 
        localStorage.removeItem("products");
        localStorage.removeItem("totalQuantity");
        localStorage.removeItem("totalPrice");
        localStorage.removeItem("alreadyPushBuy");
     };

    const customStyles = {
        content: {
            top: '54%',
            left: '50%',
            right: 'auto',
            bottom: 0,
            height: "80vh",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 0,
            width: "100%",
            backgroundColor: "grey"
        }
    };

    useEffect(() => {
        calculoTotal();

        //--------------- traigo codigo y porcentaje de promocion
        axios.get(`${config.API_URL}/promos`)
            .then(response => {
                console.log("Datos promo: ", response.data);
                setPromo({
                   promo: response.data[response.data.length - 1].promoCodigo,
                   valor: response.data[response.data.length - 1].porcentaje 
                })
                
            })
            .catch(e => {
                // Capturamos los errores
            })

    }, []);

    
    /*useEffect(() => {     // modifico subTotal, Total y descuento por promo cuando se modifica añadidos
      console.log("Se modifico productos");
      var tota = totalCompra;
      setTotalCompra(tota - (e.precio * e.cantidad));
      setSubTotal(tota - (e.precio * e.cantidad));
      tota = 0;

      var pDesc = tota * promo.valor / 100;
      var tot = totalCompra;

      setPromoDescuento(pDesc);
      setTotalCompra(tot - pDesc);

    }, [productos]);*/


    function getPromocion() {                   // click en verificar

        if (promoIngresada == promo.promo) {
            setPromoOk(true);
            showToast('Código correcto', 'success');

            // calculo promo

            var pDesc = totalCompra * promo.valor / 100;
            var tot = totalCompra;


            setPromoDescuento(pDesc);
            setTotalCompra(tot - pDesc);

        } else {
            showToast('El código es incorrecto', 'warning');
        }
    }

    function inputForm(e) {
        setDatosMail({...datosMail, [e.target.name]: e.target.value});
    }

    return (
        <div className="section-5 wf-section">
            <div className="text-block-4">Mis compras</div>
            <div className="promo">
                <p className="txtPromo">
                    Ingrese código promocional :
                </p>
                <input type="text"  disabled={promoOk} className="select-field w-input" onChange={ e => setPromoIngresada(e.target.value)} />
                <button className="botonPromo" disabled={promoOk} onClick={getPromocion}>Verificar</button>
            </div>
            <div className="wrappercompras">
                <div className="columns-4 w-row">
                    <div className="w-col w-col-3 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras negro negrita">Producto</div>
                    </div>
                    <div className="w-col w-col-2 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras negro negrita">Precio</div>
                    </div>
                    <div className="w-col w-col-2 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras negro negrita">Talle</div>
                    </div>
                    <div className="w-col w-col-2 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras negro negrita">Color</div>
                    </div>
                    <div className="w-col w-col-1 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras negro negrita">Cantidad</div>
                    </div>
                    <div className="w-col w-col-2 w-col-small-4 w-col-tiny-tiny-stack">
                        <div className="txtheadercompras cifra negro negrita">Sub total</div>
                    </div>
                </div>
                <div className="compra">
                    {
                        productos && productos.map((producto, index) => (
                            <div className="columns-4 buy w-row">
                                <div className="w-col w-col-3 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras negro">{producto.nombre}</div>
                                </div>
                                <div className="w-col w-col-2 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras negro">{producto.precio.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                                </div>
                                <div className="w-col w-col-2 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras negro">{producto.talle}</div>
                                </div>
                                <div className="w-col w-col-2 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras negro">{producto.color}</div>
                                </div>
                                <div className="w-col w-col-1 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras negro">{producto.cantidad}</div>
                                </div>
                                <div className="column-4 w-col w-col-2 w-col-small-4 w-col-tiny-4">
                                    <div className="txtheadercompras cifra negro">{(producto.precio * producto.cantidad).toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                                    <img src="images/trash.png" onClick={(e) => deleteProducto(producto, index)} loading="lazy" title="Eliminar" alt="icono trash" className="image-9" />
                                </div>
                            </div>
                        ))    
                    }    
                    {
                        productos && productos.map((producto, index) => (
                            <div className="articulophone">
                                <div className="columns-5 w-row">
                                    <div className="column-11 w-col w-col-4">
                                        <div>
                                            <div className="txtheadercompras negro negrita">Producto</div>
                                            <div className="txtheadercompras negro negrita">Talle</div>
                                            <div className="txtheadercompras negro negrita">Color</div>
                                            <div className="txtheadercompras negro negrita">Cantidad</div>
                                            <div className="txtheadercompras negro negrita">Precio</div>
                                        </div>
                                    </div>
                                    <div className="column-15 w-col w-col-5">
                                        <div className="txtheadercompras negro">{producto.nombre}</div>
                                        <div className="txtheadercompras negro">{producto.talle}</div>
                                        <div className="txtheadercompras negro">{producto.color}</div>
                                        <div className="txtheadercompras negro">{producto.cantidad}</div>
                                    </div>
                                    <div className="column-12 w-col w-col-3"><img src="images/trash.png" onClick={(e) => deleteProducto(producto, index)} loading="lazy" title="Eliminar" alt="" className="image-9" />
                                        <div className="txtheadercompras cifra negro">{producto.precio.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="columns-4 envio total w-row">
                    <div className="w-col w-col-3">
                        <div className="txtheadercompras sin">SUBTOTAL</div>
                    </div>
                    <div className="w-col w-col-6" />
                    <div className="w-col w-col-1" />
                    <div className="w-col w-col-2">
                        <div className="txtheadercompras cifra">{subTotal.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                    </div>
                </div>

                <div className="columns-4 envio w-row">
                    <div className="w-col w-col-3">
                        <div className="txtheadercompras sin">Gastos de envío</div>
                    </div>
                    <div className="w-col w-col-6">
                        <div className="txtheadercompras sin">Promoción sin cargo</div>
                    </div>
                    <div className="w-col w-col-1" />
                    <div className="column-16 w-col w-col-2">
                        <div className="txtheadercompras cifra">{(0).toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                    </div>
                </div>
                { promoOk &&
                    <div className="columns-4 envio w-row">
                        <div className="w-col w-col-3">
                            <div className="txtheadercompras sin">Descuento promoción</div>
                        </div>
                        <div className="w-col w-col-6">
                            <div className="txtheadercompras sin">{promo.valor} %</div>
                        </div>
                        <div className="w-col w-col-1" />
                        <div className="column-16 w-col w-col-2">
                            <div className="txtheadercompras cifra">{(promoDescuento).toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                        </div>
                    </div>
                }
                <div className="columns-4 envio total w-row">
                    <div className="w-col w-col-3">
                        <div className="txtheadercompras sin">TOTAL</div>
                    </div>
                    <div className="w-col w-col-6" />
                    <div className="w-col w-col-1" />
                    <div className="w-col w-col-2">
                        <div className="txtheadercompras cifra">{totalCompra.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                    </div>
                </div>
                <div className="compra" />
            </div>
            
            { productos &&
                productos.length > 0 && 
                <>
                    <div className="text-block-7">Por favor, ingrese sus datos personales para realizar el envío.</div>
                    <div className="form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get">
                            <div className="w-row">
                                <div className="column-5 w-col w-col-6">
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="nombre" data-name="Name" placeholder="Nombre" id="name" required />
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="direccion" data-name="Name 2" placeholder="Dirección" id="name-2" required />
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="ciudad" data-name="Name 3" placeholder="Ciudad" id="name-3" required />
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="codigo" data-name="Name 4" placeholder="Código postal" id="name-4" required />
                                </div>
                                <div className="column-10 w-col w-col-6">
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="provincia" data-name="Name 5" placeholder="Provincia" id="name-5" required />
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="telefono" data-name="Name 6" placeholder="Teléfono" id="name-6" required />
                                    <input type="text" className="txtfield w-input" onChange={inputForm} maxLength={256} name="colegio" data-name="Name 3" placeholder="Colegio" id="name-3" required />
                                    <input type="email" className="txtfield w-input" onChange={inputForm} maxLength={256} name="email" data-name="Name 7" placeholder="Email" id="email" required />
                                </div>
                            </div>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div> 
                    <div className="div-block-8"><img src="images/tarjetas.png" loading="lazy" sizes="(max-width: 991px) 96vw, (max-width: 2024px) 90vw, 1822px" srcSet="images/tarjetas-p-500.png 500w, images/tarjetas-p-800.png 800w, images/tarjetas-p-1080.png 1080w, images/tarjetas-p-1600.png 1600w, images/tarjetas.png 1822w" alt="" className="image-11" />
                        { productos &&
                            productos.length > 0 &&
                            <div className="div-block-10">
                                { isEnabledConfirm &&
                                    <button className="button-3 w-button" onClick={mercaPago}>Confirmar compra</button>
                                }
                            </div>
                        }
                    </div>
                </>
            }
            {iframe.status && (
                <>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => {
                            closeModal();
                            setIframe({ status: false, url: "" })
                        }}
                        style={customStyles}
                        contentLabel="Calzado Escolar"
                    >
                        <iframe
                            style={{ width: "100%", height: 430, border: 0 }}
                            src={iframe.url}
                        />
                        <div className="div-block-9 green" style={{ height: "auto", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                            <a href="./carro" className="button-2 w-button" style={{ textAlign: "center" }}>Volver al carro</a>
                            <a href="./" onClick={() => backToTheMainPage()} className="button-2 w-button" style={{ textAlign: "center", backgroundColor: "green" }}>Finalizar compra</a>
                        </div>
                    </Modal>
                </>
            )}
            <ToastContainer />
        </div>

    );
  };
  
  export default Carro;