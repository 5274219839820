import { BrowserRouter, Routes, Outlet, Route } from "react-router-dom";
import NavBar from './components/NavBar';
import Home from './screens/Home';
import Productos from './screens/Productos';
import Detalle from './screens/Detalle';
import Carro from './screens/Carro';
import Footer from './components/Footer';
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './components/ScrollToTop';

//import carritoService from './services/carritoService';

function App() {
  
  const [añadidos, setAñadidos] = useState(JSON.parse(localStorage.getItem("totalQuantity") ));

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar añadidos={añadidos} />
        <ScrollToTop />
        <Routes>
          <Route index element={<Home /> } />
          <Route path="productos" element={<Productos />} />
          <Route path="detalle/:id" element={<Detalle añadidos={añadidos} setAñadidos={setAñadidos}/>} />
          <Route path="carro" element={<Carro añadidos={añadidos} setAñadidos={setAñadidos}/>} />
        </Routes>
        <Footer />
        <ToastContainer autoClose={1500} closeOnClick/>
      </BrowserRouter>
    </div>
  );
}

export default App;

