import HTTPService from './HTTPService';

class MercadoPagoService extends HTTPService {
   constructor() {
      super("pagos/checkout");
   }
};

export default new MercadoPagoService();

