import React from 'react';
import { BrowserRouter, Routes, Outlet, Route, Link, useParams } from "react-router-dom";
import { HashLink as Linky } from 'react-router-hash-link';
import { useState, useEffect } from 'react';

const NavBar = ({ añadidos, setAñadidos }) => {
    /*console.log("********", añadidos);
    if (añadidos == null) {
        añadidos = 0;
    } */

    const [isVisible, setIsVisible] = useState(false);
    
    function muestraNavBar() {
        if (window.innerWidth < 991) {
            const nav = document.getElementById("navLinks");
            if (isVisible) {
                nav.style.height = "0";
                console.log("oculta", isVisible);
                setIsVisible(false);
            } else {
                console.log("muestra nav", isVisible);
                nav.style.height = "auto";
                setIsVisible(true);   
            }   
        }        
    }

    useEffect (() => {
        document.getElementById("navLinks").style.height = 0;
    }, []);

    return (
        <>
            
            <div className="nav">

                <input type="checkbox" id="nav-check" />
                <div className="nav-header">
                    <img className="logo" src="/images/logo2.png" loading="lazy"/>
                    <div className="nav-title">
                        overcolegial.com.ar
                    </div>
                </div>

                <div className="nav-boton" onClick={muestraNavBar} >
                    <img src="images/menu.png" />   
                </div>

                {/* <div id="navBoton" className="nav-btn" onClick={muestraNavBar} onDoubleClick={pepe}>
                    <label htmlFor="nav-check">
                        <span />
                        <span />
                        <span />
                    </label>
                </div> */}
                <div id="navLinks" className="nav-links">
                                    
                    <ul role="list" className="nav-menu w-list-unstyled">
                        <li>
                            <Linky  to="/#home" className="nav-link" onClick={muestraNavBar}>Inicio</Linky>
                        </li> 
                        <li>
                            <Linky to="/#sobreNos" className="nav-link" onClick={muestraNavBar}>Sobre nosotros</Linky>
                        </li>
                        <li>
                            <Linky to="/productos" className="nav-link" onClick={muestraNavBar}>Productos</Linky>
                        </li>
                        <li>
                            <Linky to="/#contacto" className="nav-link" onClick={muestraNavBar}>Contacto</Linky>
                        </li>
                        <li className="mobile-margin-top-10"><img src="/images/descarga.png" loading="lazy" alt="" className="image" />
                            <div id="cCounter" className="div-block">{añadidos}</div>
                            <Link to="/carro" className="nav-link" onClick={muestraNavBar}>Pagar</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
        
    );
  };
  
  export default NavBar;