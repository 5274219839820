import axios from 'axios';
import config from '../config';

class HTTPService {
    constructor(model) {
        this.api = axios.create();
        this.api.defaults.baseURL = `${config.API_URL}/${model}`;
    }
    async findAll(filter = {}) {
        return await this.api.get("", {
            params: {
                filter
            }
        })
    }

    async findByParameter(id, model) {
        return await this.api.get(`/${id}/${model}`)
    }

    async findAllByLimit(limit, offset) {
        return await this.api.get(`?filter={"limit": ${limit}, "offset": ${offset}}`);
    }
    async filterByName(name) {
        return await this.api.get(`?filter[where][nombre]=${name}`);
    }
    async findById(id) {
        return await this.api.get(`/${id}`);
    }
    async destroy(id) {
        return await this.api.delete(`/${id}`);
    }
    async create(newModel) {
        return await this.api.post("", newModel);
    }
    async update(id, newModel) {
        return await this.api.put(`/${id}`, newModel);
    }
    async updateField(id, newField) {
        return await this.api.patch(`/${id}`, newField);
    }
    async count() {
        return await this.api("/count")
    }
};

export default HTTPService;