import React from 'react';
import { BrowserRouter, Routes, Outlet, Route, Link } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from 'react';
import CalzadoService from "../services/calzadoService";
import CategoryService from "../services/categoryService";



const Productos = () => {

    const [calzadosToShow, setCalzadosToShow] = useState([]);
    const [categoriesToShow, setCategoriesToShow] = useState([]);

    /*useEffect(() => {
        //Para animaciones de webflow
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '63bdabce204da624e43e9d91');
        html.setAttribute('data-wf-site', '63b592a8f6d390693735c1ad');

        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);*/

    const findCategories = async (e) => {
        const res = await CategoryService.findAll();
        if (!res.data[0]) {
            return
        } else {
            setCategoriesToShow(res.data);
        }
    };

    const findCalzados = async (e) => {
        const res = await CalzadoService.findAll();
          if (!res.data[0]) {
            return
        } else {
            setCalzadosToShow(res.data);
        }
    };

    useEffect(() => {
        findCategories();
        findCalzados();
    }, []);

    console.log("mostrar", calzadosToShow)

    return (
        <div className="section-4 wf-section">
            <div className="div-block-4">
                <h2 className="heading-2">Productos</h2>
                <div style={{marginBottom: "0px", marginTop: "20px"}}>
                    <a href="#0" className="anchor">PEQUES</a>
                    <a href="#1" className="anchor">KIDS</a>
                    <a href="#2" className="anchor">TEENS</a>
                    <a href="#3" className="anchor">FACHAS</a>
                </div>
                <div className="wrapperproductos">
                    { 
                        categoriesToShow.map((categoryToShow, index) => (
                            
                            <>
                                {calzadosToShow.length > 0 &&
                                    <div className="titulos" id={index.toString()} style={{paddingTop: "85px"}}>
                                        <h3 className="heading-3">{categoryToShow.nombre}</h3>
                                        <h3 className="heading-4">{categoryToShow.descripcion}</h3>
                                        <div style={{ display: "flex" }}>
                                            <h3 className="heading-4">Talles :</h3>
                                            {
                                                categoryToShow.talles.map((talle) => (
                                                    <h3 className="heading-4">-{talle.talle}-</h3>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                <div class="wrappermodelos">
                                    {
                                        calzadosToShow
                                            .filter(calzado => calzado.categoriaId === categoryToShow.id)
                                            .map((calzadoToShow) => (
                                                <div className="modelo">
                                                    <h4 className="heading-5">{calzadoToShow.nombre} </h4>
                                                    <div className="text-block-5">{calzadoToShow.precio?.toLocaleString('es', { style: 'currency', currency: 'ARS' })}</div>
                                                    <img src={calzadoToShow.imagen} loading="lazy" alt="" className="image-6" />
                                                    
                                                    { calzadoToShow.destacado &&
                                                    <div className="destacado">
                                                        <div className="txtDestacado">DESTACADO</div>
                                                    </div>
                                                    }

                                                    <div className="div-block-5">
                                                        <Link to={"/detalle/" + calzadoToShow.id} className="botonproducto w-button">Ver detalle</Link>
                                                    </div>
                                                </div>
                                        ))
                                    }
                                </div>
                            
                                <div className="imgTalles">
                                    <img src={`../images/${index + 1}.png`} />
                                </div>
                            
                            
                            </>
                            
                        ))
                    }
                </div>
            </div>
        </div>
    );
  };
  
  export default Productos;