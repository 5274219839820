import React from 'react';
import { BrowserRouter, Routes, Outlet, Route, Link } from "react-router-dom";
import { useEffect, useLayoutEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import conf from "../config";


const Home = () => {

    /*useEffect(() => { 
        //Para animaciones de webflow
        window.Webflow && window.Webflow.destroy();

        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '63bdb366c764e8497b0a04d2');
        html.setAttribute('data-wf-site', '63b592a8f6d390693735c1ad');

        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);*/  

    return (
        <>
            <div id="home" className="section wf-section">
                <div className="carousel">
                    <Carousel
                        autoPlay="true"
                        infiniteLoop="false"
                        showStatus={false}
                        showThumbs={false}
                        showIndicators={false}
                        showArrows={false}
                        centerMode={true}
                        centerSlidePercentage={100}
                        dynamicHeight={true}
                        width="100%"
                        height="100%"
                        transitionTime={600}
                    >
                        <div>
                            <img src='../images/PortadaPrueba.jpg' className="imgSlider" />
                        </div>
                        <div>
                            <img src='../images/Portada-02.png' className="imgSlider"/>
                        </div>
                        {/* <div>
                            <img src='../images/33.jpg' className="imgSlider"/>
                        </div>
                        <div>
                            <img src='../images/44.jpg' className="imgSlider"/>
                        </div> */}
                    </Carousel>
                </div>

                <div className="div-block-2">
                    <h1 className="heading">EN CADA PASO DE TU ETAPA ESCOLAR</h1> 
                    <Link to="/productos" className="button-2 w-button">Ver productos</Link>
                </div>
            </div>

            <div id="sobreNos" className="section-2 wf-section">
                <div className="columns w-row">
                    <div className="column w-col w-col-6 w-col-small-small-stack">
                        <h2 className="heading-2 home">Sobre nosotros</h2>
                        <h3 className="heading-3">COLEGIALES DE CUERO PARA TODAS LAS EDADES</h3>
                        <div className="div-block-3" />
                        <p className="paragraph">Somos una empresa con 15 años de trayectoria fabricando calzados colegiales legítimos de cuero vacuno, con procesos de enchufado, pegado y cosido para obtener un producto duradero, cómodo y de alta calidad.<p> Nuestras instalaciones se encuentran en ACEBAL, Dpto ROSARIO dentro de la Prov. De SANTE FE, ARGENTINA.</p>  </p>
                        <p className="paragraph">calzadosecolARG.com nace en el año 2023 con la idea de llevar nuestros zapatos escolares a cada niño o niña que va a la escuela de manera práctica, ofreciendo envíos GRATIS a través del ente certificado Correo Argentino, que nos permite llegar a cada rincón del país.</p>
                        <p className="paragraph">Nuestros zapatos escolares son especiales para todas las edades y géneros, siendo siempre un clásico para acompañar cualquier uniforme colegial o atuendo elegante.</p>
                    </div>
                    <div className="column-2 w-col w-col-6 w-col-small-small-stack"><img src="images/nosotros.png" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 46vw, 43vw" alt="" className="image-2" /></div>
                </div>
            </div>
            <div id="productos" className="section-3 wf-section">
                <div className="columns w-row">
                    <div className="column w-col w-col-6">
                        <h2 className="heading-2 home">Nuestros productos</h2>
                        <h3 className="heading-3">DIVERSIDAD DE MODELOS</h3>
                        <h3 className="heading-3">NÁUTICOS ACORDONADOS, CON ABROJO Y GUILLERMINAS</h3>
                        <div className="div-block-3" />
                        <p className="paragraph">Colegiales para niñas, niños, adolescentes y profesores en cada paso de tu etapa escolar, desde sus primeros pasos con los “PEQUES”, de nivel inicial; aprendiendo el camino con los “KIDS”, en la primaria; al comienzo de una nueva década a tus pies con los “TEENS”, en su inicio a la secundaria y siempre siendo un clásico para modernos para los más grandes que quieren estar “FACHAS”.</p>
                        <p className="paragraph">Luego que Argentina saliera “Campeón del Mundo” nos motivamos a iniciar la campaña #juntemos11 donde nos comprometemos a donar 1 par de colegiales por cada 10 que se vendan en la tienda online, de esta manera contribuiremos a la equidad social para los que vienen del “potrero puedan jugar en primera también”.</p>
                        <Link to="/productos" className="button sinmarg w-button">Ver productos</Link>
                    </div>
                    <div className="column-2 w-col w-col-6">
                        <img src="images/nosotros2.png" loading="lazy" alt="" className="image-4" />
                        <img src="images/nosotros1.png" loading="lazy" alt="" className="image-5" />
                    </div>
                </div>

                {/* <div className="row1">
                    <div className="col1">
                        <img src="images/1-PEQUES.png" loading="lazy" alt="" className="img1" /> 
                        <img src="images/3-TEENS.png" loading="lazy" alt="" className="img1" />    
                    </div>
                    <div className="separador"></div>
                    <div className="col1">
                        <img src="images/2-KIDS.png" loading="lazy" alt="" className="img1" />
                        <img src="images/4-FACHAS.png" loading="lazy" alt="" className="img1" />
                    </div>
                </div> */}

            </div>
        </>
    );
  };
  
  export default Home;